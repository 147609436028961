<template>
  <div>
    <b-tabs class="mt-2" content-class="mt-2" justified>
      <b-tab title="À venir">
        <b-row class="match-height">
          <b-col
            lg="8"
            md="6"
          >
            <b-card title="Votre tableau de bord">
              <b-card-text>This is your second page.</b-card-text>
              <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text>
            </b-card>
          </b-col>
          <b-col
            lg="4"
            md="6"
          >
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Rappels">
      </b-tab>
      <b-tab title="Messages">
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
  },
  setup(props, {emit}) {

  }
}
</script>

<style>

</style>
